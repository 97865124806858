<template>
  <div id="inicio">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStoreCursos } from '@stores'
import Tela from '@components/pages/inicio/partials/Tela.vue'

const storeCursos = useStoreCursos()

async function carregarCursos() {
  await storeCursos.receberTodos()
}

onMounted(() => {
  carregarCursos()
})
</script>

<style scoped>
#inicio {
  padding: 0 0 0 300px;
  animation: fadeIn 0.3s ease;
}
@media screen and (max-width: 1000px) {
  #inicio {
    padding: 50px 0 0 0;
  }
}
</style>
