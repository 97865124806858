export default [
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/pages/erro/Erro.vue'),
    meta: {
      title: 'Erro'
    }
  },
  {
    path: '/:hotlink?',
    name: 'login',
    component: () => import('../components/pages/login/Login.vue'),
    meta: {
      logged: false,
      title: 'Faça seu login',
      layout: 'default-layout'
    }
  },
  {
    path: '/:hotlink?/senha',
    name: 'senha',
    component: () => import('../components/pages/senha/Senha.vue'),
    meta: {
      logged: false,
      title: 'Recuperar Senha',
      layout: 'default-layout'
    }
  },
  {
    path: '/:hotlink?/bloqueado',
    name: 'bloqueado',
    component: () => import('../components/pages/bloqueado/Bloqueado.vue'),
    meta: {
      logged: false,
      title: 'Área Bloqueada',
      layout: 'default-layout'
    }
  },
  {
    path: '/:hotlink?/loading',
    name: 'loading',
    component: () => import('../components/pages/loading/Loading.vue'),
    meta: {
      logged: false,
      title: 'Carregando...',
      layout: 'default-layout'
    }
  },
  {
    path: '/:hotlink?/perfil',
    name: 'perfil',
    component: () => import('../components/pages/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Seu perfil',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/:hotlink?/inicio',
    name: 'inicio',
    component: () => import('../components/pages/inicio/Inicio.vue'),
    meta: {
      logged: true,
      title: 'Sua área de membros',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/:hotlink?/curso/:hotlinkCurso',
    name: 'curso',
    component: () => import('../components/pages/curso/Curso.vue'),
    meta: {
      logged: true,
      title: 'Curso',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/:hotlink?/curso/:hotlinkCurso/:hotlinkModulo',
    name: 'modulo',
    component: () => import('../components/pages/modulo/Modulo.vue'),
    meta: {
      logged: true,
      title: 'Módulo',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/:hotlink?/curso/:hotlinkCurso/:hotlinkModulo/:hotlinkAula',
    name: 'aula',
    component: () => import('../components/pages/aula/Aula.vue'),
    meta: {
      logged: true,
      title: 'Aula',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  }
]
