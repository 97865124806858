<template>
  <section v-if="storeAreas.area" class="login" :class="tela.layout">
    <div class="imagem" :style="conteudoEstilo"></div>
    <div class="conteudo" :style="quadroEstilo">
      <div class="titulo">
        <img :src="`${tela.imagemLogo || 'https://arquivos.levelmember.com/imagens/default/logo.svg'}`" />
        <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de login' }}</p>
      </div>
      <div class="campos">
        <label :style="labelEstilo">E-mail da conta</label>
        <div class="inputDefault">
          <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" v-model="state.email" @keydown.enter="entrar" :style="inputEstilo" />
        </div>
        <label :style="labelEstilo">Senha de acesso</label>
        <div class="inputDefault">
          <input type="password" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keydown.enter="entrar" :style="inputEstilo" />
        </div>
        <button class="principal" @click="entrar()" :style="botaoEstilo" :disabled="state.carregando">
          {{ tela.textoBotaoEntrar || 'Entrar Agora' }}
          <span :style="spanBotaoEstilo"></span>
        </button>
        <div class="opcoes">
          <a target="_blank" v-if="tela.linkBotaoExtraUm" :href="tela.linkBotaoExtraUm" :style="botoesExtrasEstilo">{{ tela.textoBotaoExtraUm || 'Botão Extra 1' }}</a>
          <a target="_blank" v-if="tela.linkBotaoExtraDois" :href="tela.linkBotaoExtraDois" :style="botoesExtrasEstilo">{{ tela.textoBotaoExtraDois || 'Botão Extra 2' }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch, reactive, inject } from 'vue'
import { useStoreAreas } from '@stores'
import { helperToken } from '@helpers'
import apiAutenticacao from '@/api/aluno/api-autenticacao'

const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const tela = computed(() => storeAreas.area?.personalizacao?.telaLogin || {})

const state = reactive({
  email: '',
  senha: '',
  carregando: false
})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo,
  backgroundImage: `url('${tela.value.imagemFundo}')`
}))

const quadroEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const labelEstilo = computed(() => ({
  color: tela.value.corLabels,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const inputEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampos,
  color: tela.value.corTextoCampos,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  color: tela.value.corTextoBotao,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const spanBotaoEstilo = computed(() => ({
  borderColor: tela.value.corTextoBotao
}))

const botoesExtrasEstilo = computed(() => ({
  color: tela.value.corBotoesExtras,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

function atualizarFonte() {
  const fonte = tela.value.fonte || 'Inter'
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = tela.value.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

function validarDados() {
  let mensagemDeAviso

  if (!state.email || !state.senha) mensagemDeAviso = 'Email e senha obrigatórios'
  else if (state.senha.length < 6) mensagemDeAviso = 'Senha deve conter no mínimo 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: mensagemDeAviso })
  return false
}

async function entrar() {
  if (!validarDados()) return

  const idProdutor = storeAreas.area?.idProdutor
  const idArea = storeAreas.area?._id

  const payload = {
    email: state.email,
    senha: state.senha
  }

  state.carregando = true

  try {
    const resp = await apiAutenticacao.entrar(idProdutor, idArea, payload)
    localStorage.setItem(helperToken.obterKeyToken(), resp.token)
    redirecionarRota()
  } catch (error) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: error })
    state.carregando = false
  }
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function redirecionarRota() {
  const hotlink = storeAreas.area?.hotlink
  if (dominioPersonalizado()) {
    window.location.assign('/loading')
  } else if (hotlink) {
    window.location.assign(`/${hotlink}/loading`)
  }
}

watch(
  () => tela.value.corFundo,
  (novaCorFundo) => {
    document.body.style.backgroundColor = novaCorFundo
    document.documentElement.style.backgroundColor = novaCorFundo
  },
  { immediate: true }
)

watch(
  () => storeAreas.area?.personalizacao?.telaLogin,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.login {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  animation: fadeIn 0.3s ease;
  position: relative;
  min-height: 100dvh;
}

section.login.dois {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.login.dois .imagem {
  width: 100%;
}

section.login.dois .conteudo {
  width: 100%;
  max-width: 500px;
  min-height: auto;
  padding: 60px 50px;
  border-radius: 10px;
}

section.login.tres {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.login.tres .imagem {
  width: 100%;
}

section.login.tres .conteudo .titulo {
  width: 50%;
}

section.login.tres .conteudo .campos {
  width: 50%;
  max-width: 50%;
}

section.login.tres .conteudo {
  flex-direction: row;
  width: 100%;
  min-height: calc(100dvh - 160px);
  padding: 60px 50px;
  border-radius: 10px;
}

.imagem {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  min-height: 100dvh;
  z-index: 2;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 20px 0;
}

.conteudo .campos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.conteudo label {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
}

.conteudo .inputDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 0 20px 0;
}

.conteudo .inputDefault input {
  padding: 15px;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  border-radius: 10px;
  color: #262836;
  width: 100%;
  border: none;
}

.conteudo .inputDefault input::placeholder {
  opacity: 0.4;
}

.conteudo label {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 0 0 10px 0;
  color: #262836;
}

.conteudo button.principal span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent !important;
  animation: girando 0.5s linear infinite;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo button.principal:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.principal:disabled {
  color: transparent !important;
}

.conteudo button.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  background-color: #adb4c9;
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.principal:hover {
  opacity: 0.7;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 30px 0 0 0;
}

.conteudo .opcoes a {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  margin: 0 10px;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  opacity: 0.7;
}

.conteudo button.senha {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: transparent;
  margin: 10px 0 0 0;
}

@media screen and (max-width: 1000px) {
  section.login {
    flex-direction: column;
  }

  section.login.dois {
    padding: 60px 20px;
  }

  section.login.dois .conteudo {
    padding: 50px 30px;
  }

  section.login.tres {
    flex-direction: column;
    padding: 50px 20px;
  }

  section.login.tres .imagem {
    width: 100%;
  }

  section.login.tres .conteudo .titulo {
    width: 100%;
    padding: 50px 0 0 0;
  }

  section.login.tres .conteudo .campos {
    width: 100%;
    max-width: 100%;
  }

  section.login.tres .conteudo {
    flex-direction: column-reverse;
    min-height: auto;
    padding: 50px 30px;
  }

  .conteudo {
    width: 100%;
    padding: 30px;
  }
}
</style>
