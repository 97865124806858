<template>
  <div id="bloqueado">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useStoreAreas } from '@stores'
import { useRoute } from 'vue-router'
import Tela from '@components/pages/bloqueado/partials/Tela.vue'

const storeAreas = useStoreAreas()
const route = useRoute()

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function verificarAssinatura() {
  if (storeAreas.area?.assinaturaAtiva === true) {
    const caminhoBloqueado = dominioPersonalizado() ? '/bloqueado' : `/${route.params.hotlink}/bloqueado`

    if (window.location.pathname === caminhoBloqueado) {
      if (dominioPersonalizado()) {
        window.location.assign('/')
      } else {
        const hotlink = route.params.hotlink
        window.location.assign(`/${hotlink}`)
      }
    }
  }
}

watch(
  () => storeAreas.area,
  (novaArea) => {
    if (novaArea) {
      verificarAssinatura()
    }
  },
  { immediate: true }
)

onMounted(() => {
  verificarAssinatura()
})
</script>
