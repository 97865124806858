<template>
  <Alerta />
  <div class="marca" @click="irParaLevel()" v-if="storeAreas.area?.assinatura === 'free'">
    <svg viewBox="0 0 3030 307" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2978.45 172.431C2978.45 159.79 2980.87 149.334 2985.71 141.062C2990.7 132.79 2997.02 126.626 3004.67 122.568C3012.48 118.51 3020.59 116.481 3029.02 116.481V146.446C3021.84 146.446 3015.05 147.305 3008.65 149.021C3002.41 150.738 2997.34 153.547 2993.44 157.449C2989.53 161.351 2987.58 166.345 2987.58 172.431H2978.45ZM2956.68 234V116.95H2987.58V234H2956.68Z" fill="white" />
      <path d="M2879.96 236.809C2868.42 236.809 2858.19 234.234 2849.3 229.084C2840.4 223.778 2833.38 216.52 2828.23 207.313C2823.23 198.105 2820.74 187.492 2820.74 175.475C2820.74 163.458 2823.31 152.845 2828.46 143.637C2833.77 134.429 2840.95 127.25 2850 122.1C2859.21 116.794 2869.74 114.141 2881.6 114.141C2891.9 114.141 2901.35 116.872 2909.93 122.334C2918.67 127.64 2925.61 135.522 2930.76 145.978C2936.07 156.279 2938.72 168.842 2938.72 183.668H2850L2852.81 180.859C2852.81 186.634 2854.21 191.706 2857.02 196.076C2859.99 200.29 2863.73 203.567 2868.26 205.908C2872.94 208.093 2877.94 209.185 2883.24 209.185C2889.64 209.185 2894.87 207.859 2898.93 205.206C2902.98 202.396 2906.11 198.807 2908.29 194.437L2935.91 205.206C2932.64 211.76 2928.34 217.457 2923.04 222.295C2917.89 226.977 2911.72 230.567 2904.54 233.064C2897.37 235.561 2889.17 236.809 2879.96 236.809ZM2854.45 163.536L2851.64 160.727H2908.06L2905.48 163.536C2905.48 157.917 2904.15 153.469 2901.5 150.192C2898.85 146.759 2895.57 144.262 2891.67 142.701C2887.92 141.14 2884.26 140.36 2880.67 140.36C2877.08 140.36 2873.25 141.14 2869.2 142.701C2865.14 144.262 2861.63 146.759 2858.66 150.192C2855.85 153.469 2854.45 157.917 2854.45 163.536Z" fill="white" />
      <path d="M2687.6 234V70.1299H2718.5V212.229L2716.87 234H2687.6ZM2753.85 236.809C2744.02 236.809 2735.67 234.312 2728.8 229.318C2722.09 224.324 2716.94 217.223 2713.35 208.015C2709.76 198.807 2707.97 187.96 2707.97 175.475C2707.97 162.834 2709.76 151.987 2713.35 142.935C2716.94 133.727 2722.09 126.626 2728.8 121.632C2735.67 116.638 2744.02 114.141 2753.85 114.141C2764.62 114.141 2773.91 116.638 2781.71 121.632C2789.67 126.626 2795.84 133.727 2800.21 142.935C2804.58 151.987 2806.76 162.834 2806.76 175.475C2806.76 187.96 2804.58 198.807 2800.21 208.015C2795.84 217.223 2789.67 224.324 2781.71 229.318C2773.91 234.312 2764.62 236.809 2753.85 236.809ZM2745.89 208.483C2751.51 208.483 2756.43 207.079 2760.64 204.269C2765.01 201.46 2768.45 197.558 2770.94 192.564C2773.44 187.57 2774.69 181.874 2774.69 175.475C2774.69 169.076 2773.44 163.38 2770.94 158.386C2768.6 153.392 2765.25 149.568 2760.88 146.915C2756.66 144.106 2751.75 142.701 2746.13 142.701C2740.82 142.701 2736.06 144.106 2731.85 146.915C2727.63 149.724 2724.36 153.626 2722.02 158.62C2719.67 163.458 2718.5 169.076 2718.5 175.475C2718.5 181.874 2719.67 187.57 2722.02 192.564C2724.36 197.558 2727.56 201.46 2731.61 204.269C2735.83 207.079 2740.59 208.483 2745.89 208.483Z" fill="white" />
      <path d="M2483.45 234V116.95H2512.48L2513.88 132.166C2517.47 126.236 2522.08 121.788 2527.7 118.823C2533.31 115.701 2539.56 114.141 2546.42 114.141C2555.94 114.141 2563.9 116.169 2570.3 120.227C2576.86 124.285 2581.62 130.528 2584.58 138.955C2588.02 130.996 2592.85 124.909 2599.1 120.695C2605.34 116.326 2612.6 114.141 2620.87 114.141C2634.6 114.141 2645.06 118.511 2652.24 127.25C2659.42 135.834 2663.01 149.1 2663.01 167.047V234H2632.11V174.07C2632.11 166.111 2631.25 159.946 2629.53 155.576C2627.81 151.207 2625.47 148.085 2622.51 146.212C2619.54 144.183 2616.03 143.169 2611.97 143.169C2604.48 143.013 2598.71 145.432 2594.65 150.426C2590.59 155.42 2588.56 162.833 2588.56 172.666V234H2557.66V174.07C2557.66 166.111 2556.8 159.946 2555.09 155.576C2553.53 151.207 2551.18 148.085 2548.06 146.212C2545.1 144.183 2541.59 143.169 2537.53 143.169C2530.04 143.013 2524.26 145.432 2520.21 150.426C2516.3 155.42 2514.35 162.833 2514.35 172.666V234H2483.45Z" fill="white" />
      <path d="M2406.73 236.809C2395.19 236.809 2384.96 234.234 2376.07 229.084C2367.17 223.778 2360.15 216.52 2355 207.313C2350 198.105 2347.51 187.492 2347.51 175.475C2347.51 163.458 2350.08 152.845 2355.23 143.637C2360.54 134.429 2367.72 127.25 2376.77 122.1C2385.98 116.794 2396.51 114.141 2408.37 114.141C2418.67 114.141 2428.12 116.872 2436.7 122.334C2445.44 127.64 2452.38 135.522 2457.53 145.978C2462.84 156.279 2465.49 168.842 2465.49 183.668H2376.77L2379.58 180.859C2379.58 186.634 2380.98 191.706 2383.79 196.076C2386.76 200.29 2390.5 203.567 2395.03 205.908C2399.71 208.093 2404.71 209.185 2410.01 209.185C2416.41 209.185 2421.64 207.859 2425.7 205.206C2429.75 202.396 2432.88 198.807 2435.06 194.437L2462.68 205.206C2459.41 211.76 2455.11 217.457 2449.81 222.295C2444.66 226.977 2438.49 230.567 2431.31 233.064C2424.14 235.561 2415.94 236.809 2406.73 236.809ZM2381.22 163.536L2378.41 160.727H2434.83L2432.25 163.536C2432.25 157.917 2430.92 153.469 2428.27 150.192C2425.62 146.759 2422.34 144.262 2418.44 142.701C2414.69 141.14 2411.03 140.36 2407.44 140.36C2403.85 140.36 2400.02 141.14 2395.97 142.701C2391.91 144.262 2388.4 146.759 2385.43 150.192C2382.62 153.469 2381.22 157.917 2381.22 163.536Z" fill="white" />
      <path d="M2157.55 234V70.1299H2192.43L2240.19 140.828L2287.95 70.1299H2322.59V234H2290.76V120.227L2240.19 194.671L2189.62 120.461V234H2157.55Z" fill="white" />
      <path d="M2095.49 234V70.1299H2126.4V234H2095.49Z" fill="white" />
      <path d="M2018.78 236.809C2007.23 236.809 1997.01 234.234 1988.11 229.084C1979.21 223.778 1972.19 216.52 1967.04 207.313C1962.05 198.105 1959.55 187.492 1959.55 175.475C1959.55 163.458 1962.12 152.845 1967.27 143.637C1972.58 134.429 1979.76 127.25 1988.81 122.1C1998.02 116.794 2008.55 114.141 2020.42 114.141C2030.72 114.141 2040.16 116.872 2048.74 122.334C2057.48 127.64 2064.43 135.522 2069.58 145.978C2074.88 156.279 2077.54 168.842 2077.54 183.668H1988.81L1991.62 180.859C1991.62 186.634 1993.03 191.706 1995.84 196.076C1998.8 200.29 2002.55 203.567 2007.07 205.908C2011.75 208.093 2016.75 209.185 2022.05 209.185C2028.45 209.185 2033.68 207.859 2037.74 205.206C2041.8 202.396 2044.92 198.807 2047.1 194.437L2074.73 205.206C2071.45 211.76 2067.16 217.457 2061.85 222.295C2056.7 226.977 2050.54 230.567 2043.36 233.064C2036.18 235.561 2027.98 236.809 2018.78 236.809ZM1993.26 163.536L1990.45 160.727H2046.87L2044.29 163.536C2044.29 157.917 2042.97 153.469 2040.31 150.192C2037.66 146.759 2034.38 144.262 2030.48 142.701C2026.74 141.14 2023.07 140.36 2019.48 140.36C2015.89 140.36 2012.07 141.14 2008.01 142.701C2003.95 144.262 2000.44 146.759 1997.47 150.192C1994.66 153.469 1993.26 157.917 1993.26 163.536Z" fill="white" />
      <path d="M1878.31 234L1922.79 116.95H1954.62L1906.63 234H1878.31ZM1875.73 234L1827.74 116.95H1859.58L1904.06 234H1875.73Z" fill="white" />
      <path d="M1767.99 236.809C1756.44 236.809 1746.22 234.234 1737.32 229.084C1728.42 223.778 1721.4 216.52 1716.25 207.313C1711.26 198.105 1708.76 187.492 1708.76 175.475C1708.76 163.458 1711.34 152.845 1716.49 143.637C1721.79 134.429 1728.97 127.25 1738.02 122.1C1747.23 116.794 1757.77 114.141 1769.63 114.141C1779.93 114.141 1789.37 116.872 1797.95 122.334C1806.69 127.64 1813.64 135.522 1818.79 145.978C1824.09 156.279 1826.75 168.842 1826.75 183.668H1738.02L1740.83 180.859C1740.83 186.634 1742.24 191.706 1745.05 196.076C1748.01 200.29 1751.76 203.567 1756.28 205.908C1760.96 208.093 1765.96 209.185 1771.27 209.185C1777.66 209.185 1782.89 207.859 1786.95 205.206C1791.01 202.396 1794.13 198.807 1796.31 194.437L1823.94 205.206C1820.66 211.76 1816.37 217.457 1811.06 222.295C1805.91 226.977 1799.75 230.567 1792.57 233.064C1785.39 235.561 1777.2 236.809 1767.99 236.809ZM1742.47 163.536L1739.66 160.727H1796.08L1793.5 163.536C1793.5 157.917 1792.18 153.469 1789.53 150.192C1786.87 146.759 1783.59 144.262 1779.69 142.701C1775.95 141.14 1772.28 140.36 1768.69 140.36C1765.1 140.36 1761.28 141.14 1757.22 142.701C1753.16 144.262 1749.65 146.759 1746.68 150.192C1743.88 153.469 1742.47 157.917 1742.47 163.536Z" fill="white" />
      <path d="M1593.79 234V70.1299H1625.86V204.738H1697.97V234H1593.79Z" fill="white" />
      <path d="M1167 105C1167 47.0101 1214.01 8.21951e-06 1272 1.83588e-05L1368.27 3.51915e-05C1426.26 4.53307e-05 1473.27 47.0102 1473.27 105L1473.27 201.272C1473.27 259.262 1426.26 306.272 1368.27 306.272L1272 306.272C1214.01 306.272 1167 259.262 1167 201.272L1167 105Z" fill="url(#paint0_linear_110_13)" />
      <path d="M1250 84C1250 78.4771 1254.48 74 1260 74H1297C1302.52 74 1307 78.4772 1307 84V221C1307 226.523 1302.52 231 1297 231H1260C1254.48 231 1250 226.523 1250 221V84Z" fill="white" />
      <path d="M1380 174C1385.52 174 1390 178.477 1390 184L1390 221C1390 226.523 1385.52 231 1380 231L1260 231C1254.48 231 1250 226.523 1250 221L1250 184C1250 178.477 1254.48 174 1260 174L1380 174Z" fill="white" />
      <path d="M1387.09 103.011C1390.97 105.69 1390.97 111.31 1387.09 113.989L1346.87 141.754C1342.3 144.903 1336 141.719 1336 136.265L1336 80.7345C1336 75.2808 1342.3 72.0967 1346.87 75.2458L1387.09 103.011Z" fill="white" />
      <path d="M862.922 233.78V116.604H891.982L893.388 131.837C896.982 125.9 901.59 121.447 907.215 118.479C912.839 115.354 919.089 113.792 925.963 113.792C935.493 113.792 943.461 115.823 949.867 119.885C956.429 123.947 961.194 130.197 964.162 138.633C967.599 130.665 972.443 124.572 978.692 120.354C984.941 115.979 992.206 113.792 1000.49 113.792C1014.24 113.792 1024.7 118.167 1031.89 126.916C1039.08 135.509 1042.67 148.788 1042.67 166.755V233.78H1011.74V173.786C1011.74 165.818 1010.88 159.647 1009.16 155.272C1007.44 150.898 1005.1 147.773 1002.13 145.898C999.159 143.867 995.643 142.852 991.581 142.852C984.082 142.695 978.301 145.117 974.239 150.116C970.177 155.116 968.146 162.537 968.146 172.38V233.78H937.212V173.786C937.212 165.818 936.353 159.647 934.634 155.272C933.072 150.898 930.728 147.773 927.603 145.898C924.635 143.867 921.12 142.852 917.058 142.852C909.558 142.695 903.778 145.117 899.716 150.116C895.81 155.116 893.857 162.537 893.857 172.38V233.78H862.922Z" fill="white" />
      <path d="M781.824 236.592C769.95 236.592 759.326 234.014 749.952 228.859C740.734 223.547 733.469 216.282 728.157 207.064C723.002 197.846 720.424 187.222 720.424 175.192C720.424 163.162 723.002 152.538 728.157 143.32C733.313 134.102 740.5 126.916 749.718 121.76C758.936 116.448 769.482 113.792 781.355 113.792C793.385 113.792 804.009 116.448 813.227 121.76C822.445 126.916 829.632 134.102 834.787 143.32C839.943 152.538 842.521 163.162 842.521 175.192C842.521 187.222 839.943 197.846 834.787 207.064C829.632 216.282 822.445 223.547 813.227 228.859C804.165 234.014 793.698 236.592 781.824 236.592ZM781.824 208.001C787.605 208.001 792.682 206.595 797.057 203.783C801.431 200.971 804.79 197.143 807.134 192.3C809.634 187.3 810.884 181.598 810.884 175.192C810.884 168.786 809.634 163.162 807.134 158.319C804.634 153.319 801.119 149.413 796.588 146.601C792.214 143.789 787.136 142.383 781.355 142.383C775.731 142.383 770.653 143.789 766.122 146.601C761.748 149.413 758.311 153.319 755.811 158.319C753.311 163.162 752.061 168.786 752.061 175.192C752.061 181.598 753.311 187.3 755.811 192.3C758.311 197.143 761.826 200.971 766.357 203.783C770.888 206.595 776.043 208.001 781.824 208.001Z" fill="white" />
      <path d="M655.298 236.592C643.112 236.592 632.253 234.014 622.723 228.859C613.193 223.547 605.772 216.282 600.46 207.064C595.148 197.69 592.492 187.066 592.492 175.192C592.492 163.162 595.148 152.538 600.46 143.32C605.772 134.102 613.115 126.916 622.489 121.76C631.863 116.448 642.643 113.792 654.829 113.792C666.547 113.792 677.249 116.682 686.935 122.463C696.622 128.244 703.653 136.602 708.027 147.539L678.967 157.85C676.78 153.319 673.343 149.726 668.656 147.07C664.125 144.258 659.048 142.852 653.423 142.852C647.643 142.852 642.565 144.258 638.19 147.07C633.816 149.726 630.379 153.476 627.879 158.319C625.379 163.162 624.129 168.786 624.129 175.192C624.129 181.598 625.379 187.222 627.879 192.065C630.379 196.752 633.894 200.502 638.425 203.314C642.955 206.126 648.111 207.533 653.892 207.533C659.516 207.533 664.594 206.048 669.125 203.08C673.812 200.111 677.249 196.206 679.436 191.362L708.73 201.674C704.199 212.766 697.091 221.359 687.404 227.452C677.874 233.546 667.172 236.592 655.298 236.592Z" fill="white" />
      <path d="M461.879 236.592C450.006 236.592 439.382 234.014 430.008 228.859C420.79 223.547 413.525 216.282 408.213 207.064C403.057 197.846 400.479 187.222 400.479 175.192C400.479 163.162 403.057 152.538 408.213 143.32C413.369 134.102 420.555 126.916 429.773 121.76C438.991 116.448 449.537 113.792 461.411 113.792C473.441 113.792 484.065 116.448 493.282 121.76C502.5 126.916 509.687 134.102 514.843 143.32C519.998 152.538 522.576 163.162 522.576 175.192C522.576 187.222 519.998 197.846 514.843 207.064C509.687 216.282 502.5 223.547 493.282 228.859C484.221 234.014 473.753 236.592 461.879 236.592ZM461.879 208.001C467.66 208.001 472.738 206.595 477.112 203.783C481.487 200.971 484.846 197.143 487.189 192.3C489.689 187.3 490.939 181.598 490.939 175.192C490.939 168.786 489.689 163.162 487.189 158.319C484.69 153.319 481.174 149.413 476.643 146.601C472.269 143.789 467.191 142.383 461.411 142.383C455.786 142.383 450.709 143.789 446.178 146.601C441.803 149.413 438.366 153.319 435.866 158.319C433.367 163.162 432.117 168.786 432.117 175.192C432.117 181.598 433.367 187.3 435.866 192.3C438.366 197.143 441.881 200.971 446.412 203.783C450.943 206.595 456.099 208.001 461.879 208.001Z" fill="white" />
      <path d="M362.853 236.592C349.885 236.592 340.043 233.389 333.325 226.984C326.763 220.578 323.482 211.438 323.482 199.565V80.0454H354.416V192.768C354.416 197.768 355.51 201.596 357.697 204.252C359.884 206.751 363.087 208.001 367.306 208.001C368.868 208.001 370.587 207.689 372.461 207.064C374.336 206.283 376.289 205.189 378.32 203.783L389.1 226.984C385.351 229.796 381.132 232.061 376.445 233.78C371.915 235.655 367.384 236.592 362.853 236.592ZM304.499 142.852V116.604H383.71V142.852H304.499Z" fill="white" />
      <path d="M255.117 233.78V116.604H286.052V233.78H255.117ZM270.584 97.6214C265.897 97.6214 261.757 95.9029 258.164 92.4657C254.57 88.8723 252.774 84.654 252.774 79.8107C252.774 74.9675 254.57 70.8272 258.164 67.3901C261.757 63.7967 265.897 62 270.584 62C275.428 62 279.568 63.7967 283.005 67.3901C286.598 70.8272 288.395 74.9675 288.395 79.8107C288.395 84.654 286.598 88.8723 283.005 92.4657C279.568 95.9029 275.428 97.6214 270.584 97.6214Z" fill="white" />
      <path d="M176.443 236.592C164.882 236.592 154.648 234.014 145.743 228.859C136.837 223.547 129.807 216.282 124.651 207.064C119.652 197.846 117.152 187.222 117.152 175.192C117.152 163.162 119.73 152.538 124.886 143.32C130.198 134.102 137.384 126.916 146.446 121.76C155.664 116.448 166.21 113.792 178.083 113.792C188.395 113.792 197.847 116.526 206.44 121.994C215.189 127.306 222.141 135.196 227.297 145.664C232.609 155.975 235.265 168.552 235.265 183.394H146.446L149.258 180.582C149.258 186.363 150.664 191.44 153.476 195.815C156.445 200.033 160.195 203.314 164.725 205.658C169.412 207.845 174.412 208.939 179.724 208.939C186.129 208.939 191.363 207.611 195.425 204.955C199.487 202.143 202.612 198.549 204.799 194.175L232.453 204.955C229.172 211.517 224.876 217.219 219.564 222.062C214.408 226.749 208.237 230.343 201.05 232.843C193.863 235.342 185.661 236.592 176.443 236.592ZM150.899 163.24L148.086 160.428H204.565L201.987 163.24C201.987 157.616 200.659 153.163 198.003 149.882C195.347 146.445 192.066 143.945 188.16 142.383C184.411 140.821 180.739 140.039 177.146 140.039C173.553 140.039 169.725 140.821 165.663 142.383C161.601 143.945 158.085 146.445 155.117 149.882C152.305 153.163 150.899 157.616 150.899 163.24Z" fill="white" />
      <path d="M0 233.779V69.7334H105.224V99.0273H32.1062V138.398H93.0375V167.692H32.1062V233.779H0Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_110_13" x1="1465.19" y1="306.272" x2="1167" y2="11.0598" gradientUnits="userSpaceOnUse">
          <stop stop-color="#BD39FF" />
          <stop offset="0.197699" stop-color="#F84291" />
          <stop offset="0.397699" stop-color="#FF2121" />
          <stop offset="0.607699" stop-color="#FF631B" />
          <stop offset="0.792699" stop-color="#FFD000" />
          <stop offset="1" stop-color="#FF9720" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, onMounted, watch, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAreas, useStoreAlerta, useStorePerfil } from '@stores'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()
const route = useRoute()
const storeAreas = useStoreAreas()
const storeAlerta = useStoreAlerta()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const layout = computed(() => currentRoute?.value?.meta.layout || 'default-layout')

async function carregarPerfil() {
  if (route.meta.logged) {
    try {
      await storePerfil.receberPorId()
    } catch (error) {
      console.error('Erro ao carregar perfil:', error)
    }
  }
}

async function carregarArea() {
  const dominio = window.location.hostname

  if (dominio.includes('localhost') || dominio.includes('mber.live')) {
    const hotlink = route.params.hotlink
    if (hotlink) {
      await storeAreas.receberPorHotlink(hotlink)
    }
  } else {
    await storeAreas.receberPorDominioPersonalizado(dominio)
  }

  verificarAssinatura()
  document.title = storeAreas.area?.nome || 'Level Member'

  prepararMetatags()
}

function verificarAssinatura() {
  if (storeAreas.area?.assinaturaAtiva === false) {
    const caminhoBloqueado = dominioPersonalizado() ? '/bloqueado' : `/${route.params.hotlink}/bloqueado`

    if (window.location.pathname !== caminhoBloqueado) {
      window.location.assign(caminhoBloqueado)
    }
  }
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function irParaLevel() {
  window.location.href = 'https://levelmember.com'
}

function mostrarAlerta(payload) {
  storeAlerta.mostrarAlerta({
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  })
}

function prepararMetatags() {
  const favicon = storeAreas.area?.metatags?.favicon || ''
  const faviconIco = favicon.includes('.ico') ? favicon : ''
  const faviconPng = favicon.includes('.png') ? favicon : ''

  renderizarMetatags(faviconIco, faviconPng)
}

function renderizarMetatags(faviconIco, faviconPng) {
  if (faviconIco) document.querySelector('link[rel="shortcut icon"]')?.setAttribute('href', faviconIco)
  if (faviconPng) {
    const sizes = ['32x32', '192x192', '16x16']
    sizes.forEach((size) => {
      document.querySelector(`link[rel="icon"][sizes="${size}"]`)?.setAttribute('href', faviconPng)
    })
    document.querySelector('link[rel="apple-touch-icon"]')?.setAttribute('href', faviconPng)
  }

  const urlCanonical = window.location.origin + window.location.pathname
  updateLinkHref('link[rel="canonical"]', urlCanonical)
  updateMetaContent('meta[property="og:url"]', urlCanonical)
}

function updateMetaContent(selector, content) {
  document.querySelector(selector)?.setAttribute('content', content)
}

function updateLinkHref(selector, href) {
  document.querySelector(selector)?.setAttribute('href', href)
}

onMounted(() => {
  emitter.on('mostrarAlerta', mostrarAlerta)
  emitter.on('atualizarPerfil', carregarPerfil)
  carregarPerfil()
})

watch(
  () => route.params.hotlink,
  async (newHotlink, oldHotlink) => {
    if (newHotlink !== oldHotlink || newHotlink) {
      await carregarArea()
    }
  },
  { immediate: true }
)

watch(
  () => window.location.hostname,
  async (newDomain, oldDomain) => {
    if (newDomain !== oldDomain || newDomain) {
      await carregarArea()
    }
  },
  { immediate: true }
)

watch(
  () => route.path,
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      carregarPerfil()
    }
  }
)
</script>

<style scoped>
.marca {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  z-index: 100000 !important;
  background-color: #000000 !important;
  border-radius: 10px !important;
  width: 250px !important;
  height: 50px !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
}

.marca svg {
  width: 210px !important;
}

.marca:hover {
  background-color: #1e1e1e !important;
}
</style>
