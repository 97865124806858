<template>
  <div id="loading">
    <Tela />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Tela from '@components/pages/loading/partials/Tela.vue'

const route = useRoute()
const router = useRouter()

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function redirecionar() {
  if (dominioPersonalizado()) {
    setTimeout(() => {
      router.push('/inicio')
    }, 2500)
  } else {
    const hotlink = route.params.hotlink
    setTimeout(() => {
      router.push(`/${hotlink}/inicio`)
    }, 2500)
  }
}

onMounted(() => {
  redirecionar()
})
</script>

<style scoped>
#loading {
  animation: fadeIn 0.3s ease;
}
</style>
