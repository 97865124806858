<template>
  <section class="modal" :class="{ mostrar: storePerfil.mostrarModalEditarPerfil }">
    <div class="quadro" :style="quadroEstilo">
      <div class="titulo">
        <h3 :style="textosEstilo">Seu perfil na {{ storeAreas.area?.nome }}</h3>
        <button @click="fechar()" :style="botaoFecharEstilo"><Svgs nome="x" :style="botaoFecharEstilo" /></button>
      </div>
      <!-- Nome -->
      <label :style="labelEstilo">Seu nome</label>
      <input type="text" spellcheck="false" placeholder="Nome aqui..." :style="inputEstilo" autocomplete="name" v-model="state.nome" />
      <!-- Whatsapp -->
      <label :style="labelEstilo">Seu whatsapp</label>
      <input type="tel" spellcheck="false" autocomplete="phone" :style="inputEstilo" placeholder="(00) 00000-0000" v-model="state.whatsapp" v-mask="mascaraAtual" />
      <!-- Foto perfil -->
      <label :style="labelEstilo">Sua foto de perfil</label>
      <div class="inputImagem" :class="{ carregar: state.loading }" :style="inputEstilo" @click="abrirInputFile">
        <span :style="svgCarregarEstilo"></span>
        <div class="previa" :style="fundoUploadImagemEstilo">
          <Svgs :style="botaoFecharEstilo" v-if="!state.fotoPerfil" nome="arquivo" />
        </div>
        <div class="texto">
          <h3 :style="textosEstilo">{{ state.nomeArquivo || 'Subir Imagem' }}</h3>
          <p :style="textosEstilo">{{ state.tamanhoArquivo || 'Envie a imagem da sua foto' }}</p>
        </div>
        <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
      </div>
      <!-- Salvar -->
      <button class="salvar" :style="botaoEstilo" @click="salvar()">Salvar Perfil</button>
    </div>
  </section>
</template>

<script setup>
import { computed, watch, reactive, inject, ref } from 'vue'
import { useStoreAreas, useStorePerfil } from '@stores'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')
const inputFile = ref(null)

const tela = computed(() => storeAreas.area?.personalizacao?.telaLogin || {})

const quadroEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro
}))

const textosEstilo = computed(() => ({
  color: tela.value.corTitulo
}))

const labelEstilo = computed(() => ({
  color: tela.value.corLabels
}))

const botaoFecharEstilo = computed(() => ({
  fill: tela.value.corTitulo
}))

const inputEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampos,
  color: tela.value.corTextoCampos
}))

const svgCarregarEstilo = computed(() => ({
  borderColor: tela.value.corFundoBotao
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  color: tela.value.corTextoBotao
}))

const fundoUploadImagemEstilo = computed(() => ({
  backgroundImage: `url('${state.fotoPerfil}')`,
  backgroundColor: tela.value.corFundoCampos
}))

const state = reactive({
  loading: false,
  fotoPerfil: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  nome: '',
  whatsapp: ''
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 300
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoPerfil = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de perfil' })
    })
}

function fechar() {
  storePerfil.mostrarModalEditarPerfil = false
  limparCampos()
}

async function salvar() {
  const payload = {
    nome: state.nome,
    imagemPerfil: state.fotoPerfil,
    contato: {
      whatsapp: state.whatsapp
    }
  }

  if (state.nome || state.whatsapp) await storePerfil.alterarDados(payload)

  await storePerfil.receberPorId()
  emitter.emit('atualizarPerfil')
  fechar()
}

const mascaraAtual = computed(() => {
  const digitos = state.whatsapp.replace(/\D/g, '')
  return digitos.length <= 10 ? '(##) ####-####' : '(##) #####-####'
})

function preencherInputs() {
  state.nome = storePerfil.usuario?.nome
  state.whatsapp = storePerfil.usuario?.contato?.whatsapp
  state.fotoPerfil = storePerfil.usuario?.imagemPerfil
}

function limparCampos() {
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.fotoPerfil = ''
  state.nome = ''
  state.whatsapp = ''
}

watch(
  () => storePerfil.mostrarModalEditarPerfil,
  (novoValor) => {
    if (novoValor) {
      preencherInputs()
    }
  }
)
</script>

<style scoped>
.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  background-color: #00000060;
  padding: 50px 30px 30px 30px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 500px;
}
.quadro .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quadro .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: #000;
}

.quadro .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.quadro .titulo button svg {
  width: 15px;
  min-width: 15px;
  fill: #000;
}

.quadro label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: #000;
  margin: 20px 0 10px 0;
}

.quadro input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: #000;
  background-color: #e7e8f1;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
}

.inputImagem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px dashed #000;
  border-radius: 10px;
  position: relative;
  width: 100%;
  padding: 30px;
  transition: all 0.3s;
}

.inputImagem:hover {
  opacity: 0.8;
}

.inputImagem span {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #000;
  border-top: 3px solid transparent !important;
  animation: girando 1s linear infinite;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.inputImagem.carregar {
  pointer-events: none;
}

.inputImagem.carregar span {
  opacity: 1;
  visibility: visible;
}

.inputImagem.carregar .previa {
  opacity: 0;
  visibility: hidden;
}

.inputImagem.carregar .texto {
  opacity: 0;
  visibility: hidden;
}

.inputImagem .previa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 30px;
  background-color: #e7e8f1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.inputImagem .previa svg {
  width: 30px;
  min-width: 30px;
  fill: #000;
  position: absolute;
}

.inputImagem .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
  transition: all 0.3s;
}

.inputImagem .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: #000;
}

.inputImagem .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: #000;
  margin: 10px 0 0 0;
}

button.salvar {
  font-family: var(--bold);
  font-size: var(--f2);
  color: #fff;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button.salvar:hover {
  opacity: 0.6;
}
</style>
