<template>
  <section v-if="storeAreas.area" class="menu" :class="tela.layout" :style="conteudoEstilo">
    <div class="titulo">
      <a :href="`https://mber.live/${storeAreas?.area?.hotlink}`">
        <img :src="`${tela.imagemLogo || 'https://arquivos.levelmember.com/imagens/default/logo.svg'}`" />
      </a>
      <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de login' }}</p>
      <button class="menu" :class="{ ativo: state.mostrarMenu }" @click="state.mostrarMenu = !state.mostrarMenu">
        <span class="um" :style="menuEstilo"></span>
        <span class="dois" :style="menuEstilo"></span>
      </button>
    </div>
    <div class="opcoes" :style="conteudoEstilo" :class="{ mostrar: state.mostrarMenu }">
      <button :style="botoesEstilo" @click="irParaInicio()">
        <Svgs :style="botoesSvgEstilo" nome="casa" />
        Início
      </button>
      <a target="_blank" v-if="tela.linkBotaoExtraUm" :href="tela.linkBotaoExtraUm" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraUm || 'Botão Extra 1' }}
      </a>
      <a target="_blank" v-if="tela.linkBotaoExtraDois" :href="tela.linkBotaoExtraDois" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraDois || 'Botão Extra 2' }}
      </a>
      <a target="_blank" v-if="tela.linkBotaoExtraTres" :href="tela.linkBotaoExtraTres" :style="botoesEstilo">
        <Svgs :style="botoesSvgEstilo" nome="link" />
        {{ tela.textoBotaoExtraTres || 'Botão Extra 3' }}
      </a>
      <button class="perfil" @click="irParaPerfil()">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
        <div class="nome">
          <h3 :style="botoesEstilo">{{ storePerfil.usuario?.nome.split(' ')[0] }}</h3>
          <p :style="botoesEstilo">{{ storePerfil.usuario?.email }}</p>
        </div>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed, watch, reactive } from 'vue'
import { useStoreAreas, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storePerfil = useStorePerfil()
const router = useRouter()

const state = reactive({
  mostrarMenu: false
})

const tela = computed(() => storeAreas.area?.personalizacao?.menu || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo
}))

const menuEstilo = computed(() => ({
  backgroundColor: tela.value.corBotoes
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesEstilo = computed(() => ({
  color: tela.value.corBotoes,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesSvgEstilo = computed(() => ({
  fill: tela.value.corIcones
}))

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function irParaPerfil() {
  const hotlink = storeAreas.area?.hotlink
  if (dominioPersonalizado()) {
    router.push('/perfil')
  } else if (hotlink) {
    router.push(`/${hotlink}/perfil`)
  }
}

function irParaInicio() {
  const hotlink = storeAreas.area?.hotlink
  if (dominioPersonalizado()) {
    router.push('/inicio')
  } else if (hotlink) {
    router.push(`/${hotlink}/inicio`)
  }
}

function atualizarFonte() {
  const fonte = tela.value.fonte || 'Inter'
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = tela.value.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => storeAreas.area?.personalizacao?.menu,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  padding: 30px;
  z-index: 10;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titulo img {
  width: 100%;
  max-width: 130px;
  margin: 0 0 10px 0;
}

.titulo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  width: 100%;
}

.titulo button.menu {
  display: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  position: relative;
}

.titulo button.menu.ativo span {
  background-color: #ff3030;
}

.titulo button.menu.ativo span.um {
  transform: rotate(45deg);
  top: 12.5px;
}

.titulo button.menu.ativo span.dois {
  transform: rotate(-45deg);
  width: 30px;
  bottom: 12.5px;
}

.titulo button.menu span {
  position: absolute;
  right: 0;
  background-color: #000;
  height: 3px;
  transition: all 0.3s;
}

.titulo button.menu span.um {
  top: 7px;
  width: 30px;
}

.titulo button.menu span.dois {
  bottom: 7px;
  width: 25px;
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 20px 0 0 0;
}

.opcoes button {
  display: flex;
  align-items: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.opcoes a {
  display: flex;
  align-items: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.opcoes a svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.opcoes a:hover {
  opacity: 0.7;
}

.opcoes button.perfil {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 30px;
  transition: all 0.3s;
}

.opcoes button.perfil:hover {
  opacity: 0.6;
}

.opcoes button.perfil .foto {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #262836;
  background-size: cover;
  background-position: center;
}

.opcoes button.perfil .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 10px;
}

.opcoes button.perfil .nome h3 {
  font-size: var(--f3);
  font-family: var(--fonte-tela);
  color: #262836;
}

.opcoes button.perfil .nome p {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  color: #262836;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 1000px) {
  section.menu {
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    padding: 0 20px;
    z-index: 10;
  }

  .titulo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .titulo img {
    width: 100%;
    max-width: 130px;
    margin: 0;
  }

  .titulo p {
    display: none;
  }

  .titulo button.menu {
    display: flex;
  }

  .opcoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 270px;
    padding: 20px 20px;
    position: fixed;
    right: 20px;
    top: 60px;
    background-color: #fff;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scale(0.95);
    transition: all 0.3s;
  }

  .opcoes.mostrar {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: scale(1);
  }

  .opcoes button.perfil {
    display: flex;
    align-items: center;
    position: relative;
    bottom: auto;
    left: auto;
    margin: 10px 0 0 0;
  }
  .opcoes button.perfil .foto {
    width: 30px;
    height: 30px;
  }

  .opcoes button.perfil .nome {
    padding: 0 0 0 8px;
  }

  .opcoes button.perfil .nome h3 {
    font-size: var(--f2);
  }

  .opcoes button.perfil .nome p {
    font-size: var(--f1);
  }
}
</style>
