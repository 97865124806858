<template>
  <section class="modal" :class="{ mostrar: storePerfil.mostrarModalAlterarSenha }">
    <div class="quadro" :style="quadroEstilo">
      <div class="titulo">
        <h3 :style="textosEstilo">Alterar senha na {{ storeAreas.area?.nome }}</h3>
        <button @click="fechar()" :style="botaoFecharEstilo"><Svgs nome="x" :style="botaoFecharEstilo" /></button>
      </div>
      <!-- Nome -->
      <label :style="labelEstilo">Senha atual</label>
      <input type="current-password" spellcheck="false" placeholder="Senha atual..." :style="inputEstilo" autocomplete="name" v-model="state.senhaAtual" />
      <!-- Whatsapp -->
      <label :style="labelEstilo">Sua nova senha</label>
      <input type="new-password" spellcheck="false" :style="inputEstilo" placeholder="nova#senha123" v-model="state.novaSenha" />
      <!-- Salvar -->
      <button class="salvar" :style="botaoEstilo" @click="salvar()">Salvar Senha</button>
    </div>
  </section>
</template>

<script setup>
import { computed, reactive, inject } from 'vue'
import { useStoreAreas, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const tela = computed(() => storeAreas.area?.personalizacao?.telaLogin || {})

const quadroEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro
}))

const textosEstilo = computed(() => ({
  color: tela.value.corTitulo
}))

const labelEstilo = computed(() => ({
  color: tela.value.corLabels
}))

const botaoFecharEstilo = computed(() => ({
  fill: tela.value.corTitulo
}))

const inputEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampos,
  color: tela.value.corTextoCampos
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  color: tela.value.corTextoBotao
}))

const state = reactive({
  loading: false,
  senhaAtual: '',
  novaSenha: ''
})

function fechar() {
  storePerfil.mostrarModalAlterarSenha = false
  limparCampos()
}

function validarDados() {
  let mensagem
  if (state.senhaAtual === state.novaSenha) {
    mensagem = 'A nova senha não pode ser igual à senha atual'
  } else if (state.novaSenha?.length > 0 && state.novaSenha?.length < 6) {
    mensagem = 'A senha precisa ter no mínimo 6 caracteres'
  } else {
    return true
  }

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
}

async function salvar() {
  if (!validarDados()) return

  const payload = {
    senhaAtual: state.senhaAtual,
    senhaNova: state.novaSenha
  }

  if (state.senhaAtual || state.novaSenha) await storePerfil.alterarSenha(payload)

  await storePerfil.receberPorId()
  emitter.emit('atualizarPerfil')
  fechar()
}

function limparCampos() {
  state.senhaAtual = ''
  state.novaSenha = ''
}
</script>

<style scoped>
.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  background-color: #00000060;
  padding: 50px 30px 30px 30px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 500px;
}
.quadro .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quadro .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: #000;
}

.quadro .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.quadro .titulo button svg {
  width: 15px;
  min-width: 15px;
  fill: #000;
}

.quadro label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: #000;
  margin: 20px 0 10px 0;
}

.quadro input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: #000;
  background-color: #e7e8f1;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
}

.inputImagem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px dashed #000;
  border-radius: 10px;
  position: relative;
  width: 100%;
  padding: 30px;
  transition: all 0.3s;
}

.inputImagem:hover {
  opacity: 0.8;
}

.inputImagem span {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #000;
  border-top: 3px solid transparent;
  animation: girando 1s linear infinite;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.inputImagem.carregar {
  pointer-events: none;
}

.inputImagem.carregar span {
  opacity: 1;
  visibility: visible;
}

.inputImagem.carregar .previa {
  opacity: 0;
  visibility: hidden;
}

.inputImagem.carregar .texto {
  opacity: 0;
  visibility: hidden;
}

.inputImagem .previa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 30px;
  background-color: #e7e8f1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.inputImagem .previa svg {
  width: 30px;
  min-width: 30px;
  fill: #000;
  position: absolute;
}

.inputImagem .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
  transition: all 0.3s;
}

.inputImagem .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: #000;
}

.inputImagem .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: #000;
  margin: 10px 0 0 0;
}

button.salvar {
  font-family: var(--bold);
  font-size: var(--f2);
  color: #fff;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button.salvar:hover {
  opacity: 0.6;
}
</style>
