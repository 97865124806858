<template>
  <section v-if="storeAreas.area" class="modulo" :class="tela.layout" :style="conteudoEstilo">
    <div class="loading" :style="conteudoEstilo">
      <span :style="carregamentoEstilo"></span>
    </div>
    <!-- Apresentação -->
    <div class="apresentacao">
      <div class="sombra" :style="estiloSombra"></div>
      <div class="fundo desktop" :style="capaEstilo"></div>
      <div class="fundo mobile" :style="capaMobileEstilo"></div>
      <div class="texto">
        <button class="voltar" @click="voltar(storeAreas.area?.hotlink, storeCursos.curso?.hotlink)" :style="infoEstilo">
          <Svgs nome="seta" :style="iconeItemEstilo" />
        </button>
        <div class="nome">
          <h2 :style="tituloEstilo">{{ storeModulos.modulo?.nome }}</h2>
          <p :style="textoItemEstilo">{{ storeModulos.modulo?.descricao }}</p>
        </div>
        <div class="progresso" :style="infoEstilo">
          <div class="faixa" :style="progressoFaixaEstilo">
            <div class="atual" :style="progressoAtualEstilo"></div>
          </div>
          <h3 :style="textoItemEstilo">
            {{ aulasConcluidas }} de {{ totalAulas }} aulas concluídas
            <span>{{ progressoPorcentagem }}%</span>
          </h3>
        </div>
      </div>
    </div>
    <!-- Conteúdo -->
    <div class="conteudo">
      <div class="elemento" v-for="(aula, index) in storeAulas.aulas" :key="aula._id">
        <div class="aula">
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" :style="`background-image: url('${aula.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
            </div>
          </div>
          <div class="info" :style="infoEstilo">
            <h5 :style="posicaoEstilo">{{ index + 1 }}º</h5>
            <div class="nome">
              <h3 :style="tituloEstilo">{{ aula.nome }}</h3>
              <p :style="tituloEstilo">{{ aula.descricao }}</p>
            </div>
            <div class="item">
              <Svgs :style="svgItemEstilo" nome="relogio" />
              <p :style="textoItemEstilo">{{ formatarDuracao(aula.duracao) }}</p>
            </div>
            <div class="botoes">
              <button class="editar" :style="botaoEstilo" @click="irParaAula(storeAreas.area?.hotlink, storeCursos.curso?.hotlink, storeModulos.modulo?.hotlink, aula.hotlink)">
                {{ tela.textoBotao || 'Assistir' }}
              </button>
            </div>
          </div>
        </div>
        <div class="caminho um" :style="caminhoEstilo"></div>
        <div class="caminho dois" :style="caminhoEstilo"></div>
        <div class="caminho tres" :style="caminhoEstilo"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStoreAreas, useStoreAulas, useStoreCursos, useStoreModulos } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeAulas = useStoreAulas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const router = useRouter()

const tela = computed(() => storeAreas.area?.personalizacao?.telaModulo || {})

const totalAulas = computed(() => storeAulas.aulas?.length || 0)

const aulasConcluidas = computed(() => storeModulos.modulo?.progresso?.filter((aula) => aula.concluido === true).length || 0)

const progressoPorcentagem = computed(() => {
  if (totalAulas.value === 0) return 0
  return Math.round((aulasConcluidas.value / totalAulas.value) * 100)
})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo
}))

const capaEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const capaMobileEstilo = computed(() => ({
  backgroundImage: `url('${tela.value.imagemCapaMobile || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`
}))

const estiloSombra = computed(() => ({
  backgroundImage: `linear-gradient(to top, ${tela.value.corFundo}, transparent)`
}))

const progressoAtualEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  width: progressoPorcentagem.value + '%'
}))

const progressoFaixaEstilo = computed(() => ({
  backgroundColor: tela.value.corPosicaoFundo
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  color: tela.value.corTextoBotao,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const infoEstilo = computed(() => ({
  backgroundColor: tela.value.corQuadro
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulos,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const carregamentoEstilo = computed(() => ({
  borderColor: tela.value.corTitulos
}))

const svgItemEstilo = computed(() => ({
  fill: tela.value.corTags
}))

const iconeItemEstilo = computed(() => ({
  fill: tela.value.corTags
}))

const textoItemEstilo = computed(() => ({
  color: tela.value.corTags,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const posicaoEstilo = computed(() => ({
  color: tela.value.corPosicaoTexto,
  backgroundColor: tela.value.corPosicaoFundo,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const caminhoEstilo = computed(() => ({
  borderColor: tela.value.corBordaCaminho
}))

function atualizarFonte() {
  const fonte = tela.value.fonte || 'Inter'
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = tela.value.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

async function carregarAulas() {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  if (idCurso && idModulo) {
    storeAulas.receberTodos(idCurso, idModulo)
  }
}

function formatarDuracao(totalMinutos) {
  if (totalMinutos >= 60) {
    const horas = Math.floor(totalMinutos / 60)
    const minutos = (totalMinutos % 60).toString().padStart(2, '0')
    return `${horas}h${minutos}`
  }
  return `${totalMinutos}min`
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

function irParaAula(hotlinkArea, hotlinkCurso, hotlinkModulo, hotlinkAula) {
  if (dominioPersonalizado()) {
    router.push(`/curso/${hotlinkCurso}/${hotlinkModulo}/${hotlinkAula}`)
  } else {
    router.push(`/${hotlinkArea}/curso/${hotlinkCurso}/${hotlinkModulo}/${hotlinkAula}`)
  }
}

function voltar(hotlinkArea, hotlinkCurso) {
  if (dominioPersonalizado()) {
    router.push(`/curso/${hotlinkCurso}`)
  } else {
    router.push(`/${hotlinkArea}/curso/${hotlinkCurso}`)
  }
}

watch(
  () => [storeCursos.curso?._id, storeModulos.modulo?._id, tela.value.corFundo, storeAreas.area?.personalizacao?.telaModulo],
  ([idCurso, idModulo, novaCorFundo, novoValor], [oldIdCurso, oldIdModulo, oldCorFundo, oldValor] = []) => {
    if (idCurso && idModulo && (idCurso !== oldIdCurso || idModulo !== oldIdModulo)) {
      carregarAulas()
    }

    if (novaCorFundo && novaCorFundo !== oldCorFundo) {
      document.body.style.backgroundColor = novaCorFundo
      document.documentElement.style.backgroundColor = novaCorFundo
    }

    if (novoValor && novoValor !== oldValor) {
      atualizarFonte()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style scoped>
section.modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 100dvh;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100dvh;
  padding: 0 0 0 300px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: loading 1.5s ease;
}

@keyframes loading {
  0% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  80% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.loading span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top: 3px solid transparent !important;
  border: 3px solid;
  animation: girando 0.8s linear infinite;
}

.apresentacao {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100px;
  width: 100%;
}

.apresentacao .sombra {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.apresentacao .fundo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}

.apresentacao .fundo.desktop {
  display: flex;
}

.apresentacao .fundo.mobile {
  display: none;
}

.apresentacao .texto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 0 30px;
  z-index: 3;
}

.apresentacao .texto button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.apresentacao .texto button svg {
  width: 15px;
  min-width: 15px;
  transform: rotate(180deg);
}

.apresentacao .texto button:hover {
  opacity: 0.7;
}

.apresentacao .texto .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.apresentacao .texto .nome h2 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
  line-height: 1.2;
}

.apresentacao .texto .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.apresentacao .texto .progresso {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  padding: 20px;
}

.apresentacao .texto .progresso h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
  width: 100%;
  margin: 10px 0 0 0;
}

.apresentacao .texto .progresso h3 span {
  font-size: var(--f1);
}

.apresentacao .texto .progresso .faixa {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #ffffffa0;
}

.apresentacao .texto .progresso .faixa .atual {
  width: 0%;
  height: 5px;
  border-radius: 20px;
  background-color: #fff;
  transition: all 0.3s;
}

.vazio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vazio h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
}

.conteudo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
}

section.modulo.tres .conteudo {
  padding: 30px 20px 30px 20px;
}

section.modulo.dois .conteudo {
  flex-direction: column;
}

.elemento {
  display: flex;
  justify-content: center;
  position: relative;
}

section.modulo.tres .elemento:nth-child(3n + 1) {
  justify-content: center;
  padding: 0 0 30px 0;
}

section.modulo.tres .elemento:nth-child(3n + 2) {
  padding: 0 0 30px 0;
}

section.modulo.tres .elemento:nth-child(3n) {
  justify-content: center;
  padding: 0 0 30px 0;
}

section.modulo.dois .elemento:nth-child(3n + 1) {
  width: 100%;
  padding: 0;
}

section.modulo.dois .elemento:nth-child(3n + 2) {
  width: 100%;
  padding: 0;
}

section.modulo.dois .elemento:nth-child(3n) {
  width: 100%;
  padding: 0;
}

.elemento:nth-child(3n + 1) {
  justify-content: flex-start;
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n + 2) {
  justify-content: center;
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n) {
  justify-content: flex-end;
  width: 33.33%;
  padding: 0 0 100px 0;
}

section.modulo.tres .caminho {
  display: none;
}

section.modulo.dois .caminho {
  display: none;
}

.caminho {
  position: absolute;
  z-index: 1;
}

.elemento:nth-last-child(1) .caminho {
  display: none;
}

.elemento:nth-child(6n + 1) .caminho.dois {
  display: none;
}

.elemento:nth-child(6n) .caminho.dois {
  display: none;
}

/* Caminho elementos A */

.elemento:nth-child(3n + 1) .caminho.dois {
  width: 2px;
  height: 100px;
  left: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed;
}

.elemento:nth-child(3n + 1) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um {
  width: calc(50dvw - 530px);
  left: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
}

/* Caminho elementos B */

.elemento:nth-child(3n + 2) .caminho.um {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.dois {
  display: none;
}

/* Caminho elementos C */

.elemento:nth-child(3n) .caminho.um {
  display: none;
}

.elemento:nth-child(3n) .caminho.tres {
  width: calc(50dvw - 530px);
  right: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed;
}

.elemento:nth-child(3n) .caminho.dois {
  width: 2px;
  height: 100px;
  right: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed;
}

section.modulo.tres .aula {
  max-width: 100%;
  padding: 0 10px;
}

section.modulo.dois .aula {
  align-items: center;
  flex-direction: row;
  position: relative;
  max-width: 100%;
  margin: 0 0 20px 0;
}

.aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 300px;
}

section.modulo.tres .aula .capa {
  max-width: 100%;
}

section.modulo.dois .aula .capa {
  max-width: 150px;
}

section.modulo.dois .aula .capa .tamanho .imagem {
  border-radius: 10px 0 0 10px;
}

.aula .capa {
  width: 100%;
  max-width: 300px;
  z-index: 2;
}

.aula .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.aula .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

section.modulo.dois .aula .info {
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  border-radius: 0 10px 10px 0;
}

.aula .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  width: 100%;
  position: relative;
  z-index: 3;
}

section.modulo.dois .aula .info h5 {
  top: 10px;
  right: auto;
  left: -20px;
}

.aula .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dee0e6;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  text-align: center;
}

section.modulo.dois .aula .info .nome {
  padding: 0 0 0 40px;
  width: auto;
}

.aula .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  padding: 20px 20px 20px 20px;
}

.aula .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.aula .info .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

section.modulo.dois .aula .info .item {
  padding: 0 30px 0 0;
  margin-left: auto;
  width: auto;
}

.aula .info .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 10px 20px;
}

.aula .info .item p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #64677c;
}

.aula .info .item svg {
  width: 15px;
  min-width: 15px;
  fill: #64677c;
  margin: 0 5px 0 0;
}

section.modulo.dois .aula .info .botoes {
  padding: 0;
  width: auto;
}

.aula .info .botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px 20px 20px;
}

.aula .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #64677c;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 50px;
  transition: all 0.3s;
}

.aula .info .botoes button:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .menu {
    display: none;
  }

  .loading {
    padding: 0;
  }

  .apresentacao {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  .apresentacao .fundo {
    opacity: 0.3;
  }

  .apresentacao .fundo.desktop {
    display: none;
  }

  .apresentacao .fundo.mobile {
    display: flex;
  }

  .apresentacao .texto {
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px 20px 20px 20px;
    z-index: 3;
  }

  .apresentacao .texto .progresso {
    width: 100%;
    max-width: 100%;
    margin: 20px 0 0 0;
  }

  .conteudo {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  section.modulo.tres .conteudo {
    padding: 0 10px 20px 10px;
  }

  .elemento {
    flex-direction: column;
  }

  section.modulo.tres .elemento:nth-child(3n + 1) {
    justify-content: center;
    padding: 0 0 30px 0;
  }

  section.modulo.tres .elemento:nth-child(3n + 2) {
    padding: 0 0 30px 0;
  }

  section.modulo.tres .elemento:nth-child(3n) {
    justify-content: center;
    padding: 0 0 30px 0;
  }

  section.modulo.dois .elemento:nth-child(3n + 1) {
    width: 100%;
    padding: 0;
  }

  section.modulo.dois .elemento:nth-child(3n + 2) {
    width: 100%;
    padding: 0;
  }

  section.modulo.dois .elemento:nth-child(3n) {
    width: 100%;
    padding: 0;
  }

  .elemento:nth-child(3n + 1) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  .elemento:nth-child(3n + 2) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  .elemento:nth-child(3n) {
    justify-content: center;
    width: 100%;
    padding: 0 0 100px 0;
  }

  section.modulo.tres .caminho {
    display: none !important;
  }

  section.modulo.dois .caminho {
    display: none !important;
  }

  .elemento:nth-last-child(1) .caminho {
    display: none !important;
  }

  .elemento:nth-child(6n + 1) .caminho.dois {
    display: flex;
  }

  .elemento:nth-child(6n) .caminho.dois {
    display: flex;
  }

  /* Caminho elementos A */

  .elemento:nth-child(3n + 1) .caminho.dois {
    display: flex;
    left: 50%;
    bottom: 0;
  }

  .elemento:nth-child(3n + 1) .caminho.um {
    display: none;
  }

  /* Caminho elementos B */

  .elemento:nth-child(3n + 2) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n + 2) .caminho.tres {
    display: none;
  }

  .elemento:nth-child(3n + 2) .caminho.dois {
    display: flex;
    width: 2px;
    height: 100px;
    left: 50%;
    bottom: 0px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  /* Caminho elementos C */

  .elemento:nth-child(3n) .caminho.um {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.tres {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.dois {
    width: 2px;
    height: 100px;
    right: 50%;
    bottom: 0px;
    background-color: transparent;
    border-left: 3px dashed;
  }

  section.modulo.tres .aula {
    max-width: 100%;
    padding: 0 10px;
  }

  section.modulo.dois .aula {
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .aula {
    max-width: 100%;
  }

  section.modulo.dois .aula .capa {
    max-width: 100%;
  }

  section.modulo.dois .aula .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  .aula .capa {
    max-width: 100%;
  }

  section.modulo.dois .aula .info {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
  }

  .aula .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    width: 100%;
    position: relative;
    z-index: 3;
  }

  section.modulo.dois .aula .info h5 {
    top: -15px;
    right: auto;
    left: 20px;
  }

  .aula .info h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #dee0e6;
    font-family: var(--fonte-tela);
    font-size: var(--f2);
    color: #262836;
    text-align: center;
  }

  section.modulo.dois .aula .info .nome {
    align-items: center;
    padding: 0;
    width: 100%;
  }

  .aula .info .nome h3 {
    font-size: var(--f2);
  }

  .aula .info .nome p {
    font-size: var(--f1);
  }

  section.modulo.dois .aula .info .item {
    padding: 10px 0 0 0;
    margin-left: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .aula .info .item p {
    font-size: var(--f1);
  }

  .aula .info .item svg {
    width: 13px;
    min-width: 13px;
  }

  section.modulo.dois .aula .info .botoes {
    padding: 10px 0 0 0;
  }
}
</style>
